@media screen and (max-width: 375px) {
  .grecaptcha-badge {
    transform: scale(0.5) translate(-58px, 58px);
    -webkit-transform: scale(0.5) translate(-58px, 58px);
  }
}

/* .firebase-emulator-warning {
  display: none;
} */

.simplebar-placeholder {
  display: none;
}

::selection {
  background-color: #65dbd4;
}

html {
  background-color: #f9fafb;
}
